export default [
  {
    text: "Home",
    path: "/home",
    icon: "home"
  },
  {
    text: "Suporte",
    icon: "folder",
    items: [
      // {
      //   text: "Profile",
      //   path: "/profile"
      // },
      // {
      //   text: "Tasks",
      //   path: "/tasks"
      // },
      {
        text: "Clientes",
        path: "/clientes"
      }
    ]
  }
  ];
