export default
  function authHeader() {
  let user = JSON.parse(localStorage.getItem('user'));

  if (user && user.accessToken) {
    return { Authorization: 'Bearer ' + user.accessToken }; // for Spring Boot back-end
    // return { 'x-access-token': user.accessToken };       // for Node.js Express back-end
  } else {
    return {};
  }
 
}

/*
getConfigHeadersDev({})
*/
export function getConfigHeadersDev(options) {  
  
  options.onBeforeSend = function(method, ajaxOptions) {  
    let user = JSON.parse(localStorage.getItem('user'));
 
    ajaxOptions.headers = {  
          "Authorization": 'Bearer ' + user.accessToken
       }  
   }  
   return options;  
 }  


//https://supportcenter.devexpress.com/ticket/details/t734489/createstore-how-to-set-headers-for-all-requests


// function getConfig(options) {  
//   options.onBeforeSend = function(method, ajaxOptions) {  
//       ajaxOptions.headers = {  
//          "Authorization": "headerValue"  
//       }  
//   }  
//   return options;  
// }  

// let dataSource = createStore(getConfig({  
//           /*your options here*/  
// }));  