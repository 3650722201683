<template>
  <div>
    <h0 class="content-block">Modulos {{this.NomeCliente}}  </h0>
    <div class="cliente-modulos">{{ detailInfo }}</div>
    <DxDataGrid
      :data-source="dsModulos"
      :show-borders="true"
        >
     <DxColumn
      data-field="modulo" caption="Módulo"
        :hiding-priority="2">  
    </DxColumn>

    <DxColumn
      data-field="qtdeUsuarios" caption="Qtde. Usuarios"
        :hiding-priority="1"
       >  
    </DxColumn>
       
    </DxDataGrid>
  </div>
</template>
<script>



import {
  DxDataGrid,
  
  DxColumn,
 
} from "devextreme-vue/data-grid";

import { createStore } from "devextreme-aspnet-data-nojquery";
import api from '../services/api'
import {getConfigHeadersDev } from '../services/auth-header';
//import { alert } from "devextreme/ui/dialog"

const getMasterDetailGridDataSource = (id, url) => {
        let lurl = url+'/clientes/'+id+ '/modulos';
       console.log(lurl) ;
 return {

    store:  createStore(getConfigHeadersDev({
        key: "codModulo",
        loadUrl:  api.getURL(`clientes/${id}/modulos`)
     
    }))
  };
};




export default {
  components: { DxDataGrid, DxColumn },
  
  props: {
    id: {
      type: Number,
      default: () => 0
    },
    url: {
      type: String,
      default: () => ''
    },
      NomeCliente: {
      type: String,
      default: () => ''
    }
  },
  data() {
    return {
      dsModulos: getMasterDetailGridDataSource(this.id, this.url)
    };
  }
};
</script>

<style>
.master-detail-caption {
    padding: 0 0 5px 10px;
    font-size: 14px;
    font-weight: bold;
}</style>
