<template>

  <div>
    <h2 class="content-block">Clientes</h2>
    <div id="descContainer"><a @click="onStateResetClick">visão padrão</a>
      </div>
  <dx-data-grid
     ref="dataGrid"
    :data-source="dataSource"
    :selection="{ mode: 'single' }"
    :auto-navigate-to-focused-row="autoNavigateToFocusedRow"
    :focused-row-enabled="true" 
    :allow-column-reordering="true"
    :allow-column-resizing="true"
    :show-borders="true"
    
     v-model:focused-row-key="focusedRowKey"
    
   @focused-row-changing="onFocusedRowChanging"
    @focused-row-changed="onFocusedRowChanged"
 
    

  > <DxColumnChooser :enabled="true"/>
   <DxExport
        :enabled="true"
        :allow-export-selected-data="true"
   />
    <DxScrolling row-rendering-mode="virtual"/>
    <DxPaging :page-size="10"/>
      <DxPager
        :visible="true"
        :allowed-page-sizes="[5, 10, 20]"
        :show-page-size-selector=true
        :show-info="showInfo"
        :show-navigation-buttons="showNavButtons"
      />
    <DxColumn
        type="buttons"
        :width="110"
      >
       
        <DxButtonGrid
          hint="Mais dados"
          icon="info"
          @click="MaisDadosClick"
        />
         <DxButtonGrid
          hint="Chave da semana"
          icon="key"
          @click="ChaveDaSemanaClik"
        />
    </DxColumn>
    <DxColumn 
       
       data-type="number"
        
      data-field="codigo" 
        :visible='false'
        :max-width="100"
        caption="Código"> 
    </DxColumn>
 

    <DxColumn 
     :hiding-priority="15"
       data-field="nome" caption="Nome">
              
    </DxColumn>

    <DxColumn 
       :hiding-priority="14"
       data-field="cnpj"
        :visible='true' caption="CNPJ"
        :width="150"
      >
    </DxColumn>
    <DxColumn
    :hiding-priority="13"
      data-field="cidade"
     :width="200"> 
    </DxColumn>

    <DxColumn
    :hiding-priority="12"
      data-field="uf"
      :width = "100"
      :allow-grouping="true">
   
    </DxColumn>

    <DxColumn
      :hiding-priority="11"
      data-field="versao" caption="Versão"
       width="100"> 
     
    
    </DxColumn>

    <DxColumn
    :hiding-priority="10"
      data-field="status" caption="Status"
       :width="100">  
       
    </DxColumn>
   <DxColumn
   :hiding-priority="9"
      data-field="qtdeusuario" caption="Qtde Usuários"
      alignment="left" 
        
       :width="140">  
    </DxColumn>

    <DxColumn 
    :hiding-priority="8"
       data-field="consultorResponsavel" caption="Consultor" 
        :width="200">
    </DxColumn>
     <DxColumn 
     hiding-priority="7"
       data-field="vendedorResponsavel" caption="Vendedor"
        :max-width="200">
    </DxColumn>


    >
  

    <DxFilterRow :visible="true" />
    <DxHeaderFilter :visible="true" />
    <DxGroupPanel 
      :visible="true"
      :allow-column-dragging="true"
      :context-menu-enabled="true" />

    <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="storage"
      />

    <DxGroupItem summary-type="count"/>
    <DxGroupPanel :visible="true"/>
    <DxEditing
      :allow-adding="false"
      :allow-updating="true"
      :allow-deleting="true"
      mode="popup"
    />
   <DxMasterDetail
      :enabled="true"
      template="masterDetailTemplate"
    />
    <template #masterDetailTemplate="{ data: cliente }">
      <modulosCliente
        :id="cliente.key"
        :url="url"
       
      />
    </template>
  
  </dx-data-grid>
  


  </div>
</template>

<script>

import {
  DxDataGrid,
  DxColumnChooser,
  DxColumn,
  DxEditing,
  DxFilterRow,
  DxHeaderFilter,
  DxGroupPanel,
  DxMasterDetail,
  dxScrolling,
  DxPager,
  DxPaging,
  DxStateStoring,
  DxExport,
   DxButton as DxButtonGrid,
} from "devextreme-vue/data-grid";

import modulosCliente from "./cliente-modulos.vue";
import { createStore } from "devextreme-aspnet-data-nojquery";
import axios from 'axios';
import { alert } from "devextreme/ui/dialog"
import {getConfigHeadersDev } from '../services/auth-header';
import api from '../services/api';
import authHeader from '../services/auth-header';




console.log(getConfigHeadersDev({}));



const dataSource = createStore(getConfigHeadersDev( {
  key: "codigo",
  loadUrl: api.getURL('clientes'),
}));



export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxFilterRow,
    DxHeaderFilter,
    DxGroupPanel,
    DxStateStoring,  
    DxExport, 
    DxMasterDetail,
    dxScrolling,
    DxPager,
    DxPaging,
    modulosCliente,
     DxButtonGrid,
     DxColumnChooser,
    
  },
  data() {
    return {
       
       codCliente: '',
       dataSource,
      
        
    };
  },
 methods: {
       onStateResetClick() {
      this.$refs.dataGrid.instance.state(null);
    },
    onFocusedRowChanging(e) {
      var pageSize = e.component.pageSize(),
        pageIndex = e.component.pageIndex(),
        isLoading = e.component.getController('data').isLoading(),
        key = e.event && e.event.key;

      if(!isLoading) {
        if(key && e.prevRowIndex === e.newRowIndex) {
          if(e.newRowIndex === pageSize - 1) {
            e.component.pageIndex(pageIndex + 1).done(function() {
              e.component.option('focusedRowIndex', 0);
            });
          } else if(e.newRowIndex === 0) {
            e.component.pageIndex(pageIndex - 1).done(function() {
              e.component.option('focusedRowIndex', pageSize - 1);
            });
          }
        }
      }
    },
    onContainedClick() {
      // this.codCliente
    },
    ChaveDaSemanaClik(e) {
      const urlMaster = api.getURL('/masterkey/') 
       
      axios
      .get(urlMaster+e.row.data.cnpj, 
      {
        responseType: 'text/plain',
        headers:  {...authHeader(),'Content-Type' : 'application/json'} }
      
      )
      .then((response) => {
        alert(response.data.value + '<br>'+ e.row.data.nome,'Senha master');
        
        
        })
     
    },
      
    MaisDadosClick(e) {
      //alert( e.row.data.nome); 
      this.$router.push({ path: 'cliente-detalhe', query: { codCliente: e.row.data.codigo }} )
     
    },
     
    onFocusedRowChanged: function(e) {
      var data = e.row && e.row.data;
      this.codCliente = data && data.codigo;
      this.taskDetails = data && data.Task_Description;
      this.taskStatus = data && data.Task_Status;
      this.taskProgress = data && data.Task_Completion ? `${data.Task_Completion }%` : '';
      this.focusedRowKey = e.component.option('focusedRowKey');
    }
  }
};
</script>

<style scoped>
#gridContainer {
  height: 440px;
  margin-top: 30px;
}

#descContainer a {
  color: #f05b41;
  text-decoration: underline;
  cursor: pointer;
}

#descContainer a:hover {
  text-decoration: none;
}
</style>