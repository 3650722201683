
import axios from 'axios';
//import { repeat } from 'core-js/core/string';
import api from './api';

const API_URL = api.url;

export async function login(email, password)  {
  let resp = await axios
                  .post(API_URL + '/login', {
                  user: email,
                  pass: password
              }).then(response => {return response})
              .catch(error => {
                
                return error});
    
   
   if (resp.status == 200) { 
      localStorage.setItem('user', JSON.stringify(resp.data));
      
      return {Status : resp.status, Message : "Logado"};       
    }
    else
    { 
      return  {Status : resp.response.status, Message : resp.response.data };}
     
  }


    


export async function logout() {
    localStorage.removeItem('user');

  }

  export  async function isTokenValid()  {
    
    let user = JSON.parse(localStorage.getItem('user'));
    // console.log(user);
    // console.log(user.accessToken)
    
    if (user === null)
     return false
    
    try {
        const resp = await axios.post(API_URL + '/istokenauth', {
        token: user.accessToken,
    })
      console.log(resp.status);
      return resp.status == 200
    } catch{ 
      console.log('token nao autorizado')
      logout() 
      return false
    }
    // return axios.post(API_URL + '/istokenauth', {
    //   token: user.token,
    // }).then( resp => {
    //                   console.log(resp.status);
    //                   return resp.status == 200
    //                   }
    //         )
    // .catch( ()=> { this.logout; return  false}) ;
    
  }
  


 


