<template>
  <div>
    <h2 class="content-block">Estatísticas</h2>

    <DxChart ref="chart">
      <DxTooltip
        :enabled="true"
        :customize-tooltip="customizeTooltip"
      />
      <DxAdaptiveLayout :width="450"/>
      <DxSize :height="400"/>
      <DxCommonSeriesSettings type="bar"/>
    </DxChart>

    <DxPivotGrid
      id="pivotgrid"
      ref="grid"
      :data-source="dataSource"
      :allow-sorting-by-summary="true"
      :allow-filtering="true"
      :show-borders="true"
      :show-column-grand-totals="true"
      :show-row-grand-totals="true"
      :show-row-totals="true"
      :show-column-totals="true"
       @cell-click="onCellClick"
    >
      <DxFieldChooser
        :enabled="true"
        :height="400"
      />
      <DxFieldPanel :visible="true"/>
      
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="dx-widget-gallery-pivotgrid-storing"
      />

    </DxPivotGrid>
<DxPopup
      :width="600"
      :height="400"
      :title="popupTitle"
      v-model:visible="popupVisible"
      @shown="onShown"
    >
      <DxDataGrid
        :ref="dataGridRefName"
        :width="560"
        :height="300"
        :data-source="drillDownDataSource"
      >
        <DxColumn data-field="cidade"/>
        <DxColumn data-field="city"/>
        <DxColumn
          data-field="codigo"
          data-type="number"
          format="number"
        />
        <DxColumn
          data-field="date"
          data-type="date"
        />
      </DxDataGrid>
    </DxPopup>
  </div>
</template>
<script>

import {
  DxChart,
  DxAdaptiveLayout,
  DxCommonSeriesSettings,

  DxSize,
  DxTooltip,
} from 'devextreme-vue/chart';

import {
  DxPivotGrid,
  DxFieldChooser,
  DxStateStoring,
  DxFieldPanel
} from 'devextreme-vue/pivot-grid';


import api from '../services/api';
//import authHeader from '../services/auth-header';
import {getConfigHeadersDev } from '../services/auth-header';

import { createStore } from 'devextreme-aspnet-data-nojquery';
import { DxPopup } from 'devextreme-vue/popup';



export default {
  components: {
    DxChart,
    DxAdaptiveLayout,
    DxCommonSeriesSettings,
    DxSize,
    DxTooltip,
    DxPivotGrid,
    DxFieldChooser,
    DxPopup,
    DxStateStoring,
  
    DxFieldPanel
  },

     dataGridRefName: 'dataGrid',
      drillDownDataSource: null,
      popupTitle: '',
      popupVisible: false,   

  data() {
      return {
      dataSource: {
        remoteOperations: false,
        store: createStore(getConfigHeadersDev( {
          key: "codigo",
          loadUrl: api.getURL('clientes'),
        })),
         fields: [
        {
          caption: 'versaoPrincipal',
          dataField: 'versaoPrincipal',
          width: 250,
          expanded: false,
          sortOrder: 'asc',
          area: 'row'
         },
         {
          
          caption: 'contador',
          summaryType: 'count',
          area: 'data'
         }
        ]
      },
     
      
      customizeTooltip: function(args) {
        const valueText = args.originalValue;
        return {
          html: `${args.seriesName} | Total   ${valueText}</div>`
        };
      }
    };
  },
  
   methods: {
    onCellClick(e) {
      if (e.area == 'data') {
       
        var pivotGridDataSource = e.component.getDataSource(),
          rowPathLength = e.cell.rowPath.length,
          rowPathName = e.cell.rowPath[rowPathLength - 1];
        this.drillDownDataSource = pivotGridDataSource.createDrillDownDataSource(e.cell);
        this.popupTitle = `${
          rowPathName ? rowPathName : 'Total'
          
        } Drill Down Data`;
        this.popupVisible = !this.popupVisible;
      }
    },
    
    onShown() {
      this.dataGrid.updateDimensions();
      alert('passou');
    }
    
  },
 

  mounted() {
    const pivotGrid = this.$refs.grid.instance;
    const chart = this.$refs.chart.instance;
    pivotGrid.bindChart(chart, {
      dataFieldsDisplayMode: 'splitPanes',
      alternateDataFields: false
    });
    const dataSource = pivotGrid.getDataSource();
    setTimeout(function() {
      dataSource.expandHeaderItem('row', ['North America']);
      dataSource.expandHeaderItem('column', [2013]);
    }, 0);
  }
};
</script>
<style>
#pivotgrid {
    margin-top: 20px;
}
.currency {
    text-align: center;
}
</style>
