<template>
  <div>
    <div class="long-title"><h3>Detalhes de <b> {{dataSource.nome}} </b> </h3></div>
    <div id="form-container">
      <DxForm
        id="form"
        :on-content-ready="validateForm"
        :col-count="1"
        :form-data="dataSource"
      > <DxGroupItem
           :col-count="3" >
            <DxSimpleItem
            :editor-options="{readOnly:  true}"
            data-field="codigo"
            IsReadOnly=true
            />
        </DxGroupItem>
        <DxGroupItem
            :col-count="3" >
            <DxSimpleItem
            :editor-options="{readOnly:  true}"
            :col-span="3"
            data-field="nome"
            
            />

            <DxSimpleItem
            :editor-options="{readOnly:  true}"
            
            data-field="cnpj"
            />
            
            <DxSimpleItem
            data-field="versao"

            editor-type="dxSelectBox"  
            :editor-options="{readOnly: !TemPerAlteraVersaoCliente, dataSource: DsVersaoSitemaCPS,key:'codigo',  displayExpr: 'nome',valueExpr:'nome',searchEnabled: true,searchExpr:['nome'],searchMode:'contains',minSearchLength:0}"    

            />

            <DxSimpleItem
            :editor-options="{readOnly: true}"
            data-field="status"
            />
            
            <DxSimpleItem
            
            :editor-options="{readOnly: !TemPerAlteraQtdeUsuarios}"
            
            data-field="qtdeusuario"
            />

            <DxSimpleItem
            data-field="consultorResponsavel"
           editor-type="dxSelectBox"  
            :editor-options="{readOnly: !TemPerAlterarDados, dataSource: dsUsers,key:'codigo',  displayExpr: 'nome',valueExpr:'nome',searchEnabled: true,searchExpr:['nome'],searchMode:'contains',minSearchLength:0}"    

            />
        
            <DxSimpleItem
            data-field="vendedorResponsavel"
            editor-type="dxSelectBox"  
            :editor-options="{readOnly: !TemPerAlterarDados, dataSource: dsUsers,key:'codigo',  displayExpr: 'nome',valueExpr:'nome',searchEnabled: true,searchExpr:['nome'],searchMode:'contains',minSearchLength:0}"    

            />

          
        </DxGroupItem>
           <DxSimpleItem
                :heigth="222"
                :editor-options="{readOnly: !TemPerAlterarDados}"
                data-field="obs"
                
                editor-type="dxTextArea"
            />

        <DxGroupItem>


        </DxGroupItem>

      </DxForm>
    </div>
    <DxButton
              :width="120"
              text="Salvar"
              type="normal"
              styling-mode="contained"
              v-if="(TemPerAlteraVersaoCliente||TemPerAlteraQtdeUsuarios||TemPerAlterarDados)" disabled 
              @click="onClick($event)"
    />
     <DxButton
              :width="120"
              text="Licença"
              type="normal"
              styling-mode="contained"
              v-if="TemPerGerarChave" disabled 
              @click="onClickDownChave($event)"
              
    />
  </div>
  
</template>
<script>
import { DxForm, DxSimpleItem, DxGroupItem} from 'devextreme-vue/form';
import 'devextreme-vue/text-area';
import api from '../services/api'
import DxButton from 'devextreme-vue/button';
import notify from 'devextreme/ui/notify';
import axios from 'axios';
import authHeader from '../services/auth-header';
import { alert } from "devextreme/ui/dialog"
//import DataSource from 'devextreme/data/data_source';
// import { createStore } from "devextreme-aspnet-data-nojquery";

export default {
  components: {
    DxForm,
    DxSimpleItem,
    DxButton, DxGroupItem
  },
  data() {
    return {
      dataSource: {},
      permissoes: api.getPermissoes(),
    //   DsVersaoSitemaCPS : ['teste1', 'teste2', 'teste3'],
      DsVersaoSitemaCPS : [
          {nome:'Teste', codigo:0},
          {nome:'Teste2', codigo:1},
          {nome:'Teste3', codigo:2},
      ],
       dsUsers : [
          {nome:'Teste', codigo:0},
          {nome:'Teste2', codigo:1},
          {nome:'Teste3', codigo:2},
      ],
      TemPerAlteraVersaoCliente: false,
      TemPerAlteraQtdeUsuarios: false,
      TemPerAlterarDados: false,  
      TemPerGerarChave : false, 
      validationRules: {
        position: [
          { type: 'required', message: 'Position is required.' },
        ],
        hireDate: [
          { type: 'required', message: 'Hire Date is required.' }
        ]
      },
    //   versao: 0,
    };
  },
  methods: {
    validateForm(e) {
      e.component.validate();
    },
  

    async onClick() {
       
          let lbody = JSON.stringify( {
            versao : this.dataSource.versao,
            vendedorResponsavel : this.dataSource.vendedorResponsavel,
            consultorResponsavel : this.dataSource.consultorResponsavel,
            qtdeusuario : this.dataSource.qtdeusuario,
            obs : this.dataSource.obs

        });
     
        let  lurl = api.getURL('clientes/sistema/') +  this.dataSource.codigo ;
        await axios({
                    url : lurl,
                    method : 'PATCH',
                    headers: {...authHeader(),'Content-type' : 'application/json'},
                    data : lbody,
                }).then((response) => {
                        notify(response.data)
                   }
                  ).catch(async function  (error) {
                    alert(error.response.data,'Dados da da licenca');
                  });

        lurl = api.getURL('clientes/') +  this.dataSource.codigo ;
        await axios({
                    url : lurl,
                    method : 'PATCH',
                    headers: {...authHeader(),'Content-type' : 'application/json'},
                    data : lbody,
                }).then((response) => {
                        notify(response.data)
                        console.log('salvou')
                   }
                  ).catch(async function  (error) {
                    alert(error.response.data,'Dados da empresa');
                  });

      //  fetch(api.getURL(`/clientes/sistema/${this.$route.query.codCliente}`), { 
      //       method: "PATCH",  
      //       headers: {    "Content-type": "application/json"  },  
      //       body: JSON.stringify(DataSource.datadata) } )
            
      //    .then(response => {   notify('Salvo com sucesso');     
      //    return response.json();  })  .then(data => console.log(data));

      
    },
  async  onClickDownChave() {
     let  lurl = api.getURL('gerachave/') +  this.dataSource.cnpj + '/' + this.dataSource.versao ; 
     await axios({
     url: lurl,
     headers: {...authHeader(),'responseType':'blob', 'x-Ingora-Versao-Atual' : 'true'} ,
     method: 'GET',
    responseType: 'blob',
}).then((response) => {
     var fileURL = window.URL.createObjectURL(new Blob([response.data]));
     var fileLink = document.createElement('a');
    
     fileLink.href = fileURL;
     fileLink.setAttribute('download',  this.dataSource.cnpj + '.zip');
     document.body.appendChild(fileLink);
     
     fileLink.click();
     
}).catch(async function  (error) {
    let errormsg =await (error.response?.data)?.text();
    console.log(error.response.data.text())
    alert(errormsg,'Aviso');
  });
     
    //  axios
    //     .get(lurl, { headers:   {'Content-Type': 'multipart/form-data'} }, {responseType : 'blob'})
    //   .then(response => {
    //          console.log(response.data)
    //           const url = window.URL.createObjectURL(new Blob([response.data], {type: 'blob'}));
    //           const link = document.createElement('a');
    //           link.href = url;
    //          link.setAttribute('download', this.dataSource.cnpj + '.zip')  //or any other extension
    //          document.body.appendChild(link);
    //          link.click()

    //   }) .catch(function (error) {
    //       if (error.response) {
    //           alert(error.response.data,'Aviso!');
    //       } else if (error.request) {
    //           alert(error.response.request,'Aviso!');
    //       } else {
    //                 alert(error.message,'Aviso!')
    //               }

    //     })
     

       
      
    }
  },
  async  created() 
  {
  
     await axios({
     url: api.getURL('/users'),
     headers: {...authHeader(),'responseType':'blob', 'x-Ingora-Versao-Atual' : 'true'} ,
     method: 'GET',
}).then((response) => {
     this.dsUsers = response.data;
     console.log(this.dsUsers)
     
}).catch(async function  (error) {
    let errormsg =await (error.response?.data)?.text();
    console.log(error.response.data.text())
    alert(errormsg,'Aviso');
  });

   


   fetch(api.getURL('/tabela/tversao'))
        .then(r => r.json())
         .then(j => {
             this.DsVersaoSitemaCPS = j.data
             console.log(this.DsVersaoSitemaCPS)
         })
         .catch(e => console.error(e));

    console.log('aqui')
    let lurl = api.getURL(`/clientes/${this.$route.query.codCliente}`);
    await axios
      .get(lurl, { headers: authHeader() })
        .then(response => {
             this.dataSource =response.data   
        })
        .catch(e => console.error(e))
      
    try
    {  
     let response = await axios
          .get(api.getURL('isauthorized/G01/110'), { headers: authHeader()});
        this.TemPerAlteraVersaoCliente =  (response.status == 200)  
      
    }catch{
      this.TemPerAlteraVersaoCliente = false;
    }

   try
   {
    let response = await axios
      .get(api.getURL('isauthorized/G01/100'), { headers: authHeader()});
    this.TemPerAlteraQtdeUsuarios =  (response.status == 200);
   }catch{this.TemPerAlteraQtdeUsuarios=false}


   try
   {
    let response = await axios
      .get(api.getURL('isauthorized/G01/70'), { headers: authHeader()});
    this.TemPerGerarChave =  (response.status == 200);
   }catch{this.TemPerGerarChave=false}



   try {
     let response = await axios
    .get(api.getURL('isauthorized/G01/30'), { headers: authHeader()});
      this.TemPerAlterarDados =  (response.status == 200) 
  }catch{this.TemPerAlterarDados = false}     
  },
  mounted() {
      console.log(`DATASOURCE: ${this.dataSource}`)
      console.log(`DsVersaoSitemaCPS: ${this.DsVersaoSitemaCPS}`)  
      console.log(this.TemPerAlteraVersaoCliente);
        }
};
</script>
<style scoped>
#form-container {
    margin: 10px 10px 30px;
}

.long-title h3 {
    font-family: 'Segoe UI Light', 'Helvetica Neue Light', 'Segoe UI', 'Helvetica Neue', 'Trebuchet MS', Verdana;
    font-weight: 200;
    font-size: 28px;
    text-align: center;
    margin-bottom: 20px;
}
</style>
